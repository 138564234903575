import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from 'common/theme/cryptoModern';
import { ResetCSS } from 'common/assets/css/style';

import Sticky from 'react-stickynode';
import Navbar from '../containers/CryptoModern/Navbar';
import Footer from '../containers/CryptoModern/Footer';
import GlobalStyle, {
  BaseWrapper,
  ContentWrapper,
} from '../containers/CryptoModern/base.style';
import {ModalProvider} from "../common/contexts/ModalContext";
import styled from 'styled-components';
import useWindowDimensions from '../common/hooks/useWindowDimensions';
import { graphql, useStaticQuery } from 'gatsby';
import Image from '../common/components/Image';
import Fade from 'react-reveal/Fade';

const SmallImage = styled(Image)`
  width: 60px;
  height: 60px;
  margin-right: 20px;
  object-fit: contain;
  @media (max-width: 992px) {
    width: 40px;
    height: 40px;
  }
`


const Wrap = () => {

  const {width} = useWindowDimensions()
  if (typeof window === `undefined`) {
    return(<></>);
  }

  return (
      <ThemeProvider theme={theme}>
        <>
          <ResetCSS />
          <GlobalStyle />
          <div className={"radial"}/>
          <ModalProvider>
            <BaseWrapper>
              <Sticky top={0} innerZ={9999} activeClass="sticky-active">
                <Navbar />
              </Sticky>
              <ContentWrapper id = "wrapper">
                
                <Footer />
              </ContentWrapper>
            </BaseWrapper>
          </ModalProvider>
        </>
      </ThemeProvider>
  );
}

export default Wrap;

export function Head() {

  const detailsQuery = useStaticQuery(graphql`
  query DefaultSEOQueryWrap {
    site {
      siteMetadata {
        title
        description
        author
        keywords
        image
        url
      }
    }
  }
`);

return (
  <>
    <html lang={'it'} />
    <title>{`Wrap | ${detailsQuery.site.siteMetadata.title}`}</title>
    <meta name="description" content={detailsQuery.site.siteMetadata.metaDescription} />
    <meta property="og:title" content={`${detailsQuery.site.siteMetadata.title} | Wrap`} />
    <meta property="og:description" content={`Nologaroia - Noleggio mezzi aziendali, piattaforme aeree e molto altro`} />
    <meta property="og:url" content={detailsQuery.site.siteMetadata.url} />
    <meta property="og:type" content="website" />
    <meta property="og:image" content={detailsQuery.site.siteMetadata.image} />
    <meta property="og:image:type" content="image/png" />
    <meta property="og:image:width" content="1200" />
    <meta property="og:image:height" content="630" />
    <meta property="og:image:alt" content="Nologaroia - Noleggio mezzi aziendali, piattaforme aeree e molto altro" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@Nologaroia" />
    <meta name="twitter:url" content={detailsQuery.site.siteMetadata.url} />
    <meta name="twitter:image" content={detailsQuery.site.siteMetadata.image} />
    <meta name="twitter:creator" content="@Nologaroia" />
    <meta name="twitter:title" content={detailsQuery.site.siteMetadata.title} />
    <meta name="twitter:description" content={detailsQuery.site.siteMetadata.metaDescription} />
    {detailsQuery.site.siteMetadata.keywords.length > 0 && (
      <meta name="keywords" content={detailsQuery.site.siteMetadata.keywords.join(', ')} />
    )}
  </>
);
}
